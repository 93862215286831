var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"event-list"}},[_c('event-add-update',{attrs:{"event-type":_vm.eventType,"event-item":_vm.eventItem},on:{"refetch-data":_vm.fetchEvents,"upload-image":_vm.uploadImage,"save-event-event":_vm.saveEventEvent},model:{value:(_vm.isAddUpdateEventSidebarActive),callback:function ($$v) {_vm.isAddUpdateEventSidebarActive=$$v},expression:"isAddUpdateEventSidebarActive"}}),_c('event-filter',{on:{"filter-data":_vm.filterData},model:{value:(_vm.isFilterEventSidebarActive),callback:function ($$v) {_vm.isFilterEventSidebarActive=$$v},expression:"isFilterEventSidebarActive"}}),_c('Image-upload',{attrs:{"data":_vm.dataUpload,"isUploadImageModul":_vm.modul,"isActive":_vm.image_isActive,"isImageSize":_vm.imageSize},on:{"refetch-data":_vm.fetchEvents,"save-image-event":_vm.saveImageEvent},model:{value:(_vm.isUploadEventImageActive),callback:function ($$v) {_vm.isUploadEventImageActive=$$v},expression:"isUploadEventImageActive"}}),_c('v-snackbars',{attrs:{"objects":_vm.snackbars,"width":"500","top":"","right":"","transition":"slide-y-transition"},on:{"update:objects":function($event){_vm.snackbars=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var close = ref.close;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])}),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBullhorn)+" ")]),_vm._v(" Duyurular ")],1),_c('v-spacer'),(_vm.userData.role !== 'PLSYR')?_c('v-btn',{staticClass:"text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text",attrs:{"depressed":"","x-large":""},on:{"click":function($event){return _vm.addEvent()},"mouseenter":function($event){_vm.activeHover = true},"mouseleave":function($event){_vm.activeHover = false}}},[_c('span',[_vm._v("Duyuru Ekle")]),(_vm.activeHover)?_c('span',[_c('img',{attrs:{"src":require("@/assets/images/misc/tukas-yaprak.png"),"width":"20"}})]):_vm._e()]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.filteredTableColumns,"items":_vm.eventListTable,"options":_vm.options,"items-per-page":15,"fixed-header":"","height":_vm.dataTableHeight,"server-items-length":_vm.totalEventListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dosya_adi",fn:function(ref){
var item = ref.item;
return [(item.images)?_c('v-tooltip',{attrs:{"right":"","color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"rounded d-flex align-center justify-center",attrs:{"tile":"","width":!_vm.$vuetify.breakpoint.xsOnly ? 40 : 60,"height":!_vm.$vuetify.breakpoint.xsOnly ? 40 : 60,"color":item.images ? 'white' : 'primary'}},'v-card',attrs,false),on),[_vm._l((item.images),function(image,index){return _c('v-img',{key:index,staticClass:"mr-2",attrs:{"src":'https://portalapi.tukas.com.tr/' +image,"width":!_vm.$vuetify.breakpoint.xsOnly ? 30 : 50,"height":!_vm.$vuetify.breakpoint.xsOnly ? 30 : 50}})}),(item.images.length <= 0)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")]):_vm._e()],2)]}}],null,true)},[_c('v-img',{attrs:{"width":"250","height":"250","src":'https://portalapi.tukas.com.tr/' + item.images}})],1):_c('v-card',{staticClass:"rounded d-flex align-center justify-center",attrs:{"tile":"","width":!_vm.$vuetify.breakpoint.xsOnly ? 40 : 60,"height":!_vm.$vuetify.breakpoint.xsOnly ? 40 : 60,"color":item.images ? 'white' : 'primary'}},[_vm._l((item.images),function(image,index){return _c('v-img',{key:index,staticClass:"mr-2",attrs:{"src":'https://portalapi.tukas.com.tr/' +image,"width":!_vm.$vuetify.breakpoint.xsOnly ? 30 : 50,"height":!_vm.$vuetify.breakpoint.xsOnly ? 30 : 50}})}),(item.images.length <= 0)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")]):_vm._e()],2)]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.is_active === '1' ? 'success' : 'warning',"link":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.is_active === '1' ? 'Aktif' : 'Pasif')+" ")])]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [(_vm.userData.role !== 'PLSYR')?_c('div',{domProps:{"textContent":_vm._s(item.content)}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userData.role !== 'PLSYR')?[_c('v-btn',{staticClass:"text-capitalize text-caption",attrs:{"depressed":"","color":"nightDark","small":""},on:{"click":function($event){return _vm.editEvent(item)}}},[_vm._v(" Düzenle ")]),_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"error","x-small":"","plain":""},on:{"click":function($event){return _vm.eventDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Duyuru Sil")])]),(item.images.length>0)?_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-l-0 rounded-r-xl",attrs:{"depressed":"","color":"warning","x-small":"","plain":""},on:{"click":function($event){return _vm.eventImageDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Resimi Kaldır")])]):_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-l-0 rounded-r-xl",attrs:{"depressed":"","color":"nightDark","x-small":""},on:{"click":function($event){return _vm.eventImageUpload(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCamera)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Resim Yükle")])])]:[_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"warning","x-small":"","plain":""},on:{"click":function($event){return _vm.openDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Detayını Oku")])])]]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selectedEvent.baslik)+" "),_c('v-spacer'),_c('v-icon',{staticStyle:{"cursor":"pointer","color":"#ff4081"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.icons.mdiClose))])],1),_c('v-card-text',[_c('p',[_vm._v(_vm._s(_vm.selectedEvent.content))]),(_vm.selectedEvent.image)?[_c('v-img',{staticClass:"mt-2",staticStyle:{"object-fit":"cover"},attrs:{"src":'https://portalapi.tukas.com.tr/' + _vm.selectedEvent.image,"width":"100%","max-height":"300"}})]:[_c('v-icon',{staticClass:"ma-0",staticStyle:{"font-size":"100px","color":"#cccccc"},attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")])]],2)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }